var menu = {
	
	_init: function(){
		
		this.rwdMenuOnClick();
		
	},
	
	rwdMenuOnClick:function(){
		
		$( '#small-screen' ).click( function (){
			
			var menu = $('header nav ul');
			
			$("header").toggleClass("expanded");
			
			menu.toggleClass("visible");
			
			$("#cross").toggleClass("visible");
			
			$("#menu-open-button").toggleClass("invisible");
			
			$("#lang-switch").toggleClass("visible");
			if( menu.hasClass('visible')) {
				
				$("#lang-switch").addClass("visible");
				
				$('header nav ul li').click( function (){
					
					menu.removeClass("visible");
					
					$("#lang-switch").removeClass("visible");
					
					$("header").removeClass("expanded");
					
					$("#cross").removeClass("visible");
					$("#menu-open-button").removeClass("invisible");
					
				});
				
			}

			$('body').click(function(event){
				
				
				if($(event.target).closest('header').length > 0){
					
				}else{
					
					$("header").removeClass("expanded");
					
					menu.removeClass("visible");
					
					$("#lang-switch").removeClass("visible");
					
					$("#cross").removeClass("visible");
				}
			});
		});
	}
};
$(document).ready(function() {
	
	menu._init();
	
});